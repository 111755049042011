import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Row } from 'react-bootstrap';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';

const SoloIndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page solo'} game="solo">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Solo Leveling: Arise wiki and tier list</h1>
        <h2>
          Prydwen.gg is a wiki for Solo Leveling: Arise. Check our guides, tier
          lists and reviews for characters available in the game.
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/solo-leveling/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/solo/categories/category_intro.jpg"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Reroll guide"
          link="/solo-leveling/guides/reroll"
          image={
            <StaticImage
              src="../../images/solo/categories/category_reroll.jpg"
              alt="Reroll guide"
            />
          }
          updated
        />
        <CategoryCard
          title="Beginner guide"
          link="/solo-leveling/guides/beginner-guide"
          image={
            <StaticImage
              src="../../images/solo/categories/category_beginner.jpg"
              alt="Reroll guide"
            />
          }
        />
        <CategoryCard
          title="Best skills for Jinwoo"
          link="/solo-leveling/guides/jinwoo-skills"
          image={
            <StaticImage
              src="../../images/solo/categories/category_skill.jpg"
              alt="Best skills for Jinwoo"
            />
          }
          updated
        />
        <CategoryCard
          title="Best stats and gear for Jinwoo"
          link="/solo-leveling/guides/jinwoo-stats"
          image={
            <StaticImage
              src="../../images/solo/categories/category_stats.jpg"
              alt="Best stats for Jinwoo"
            />
          }
          updated
        />
        <CategoryCard
          title="Jinwoo's Best Blessing Stones"
          link="/solo-leveling/guides/jinwoos-best-blessing-stones"
          image={
            <StaticImage
              src="../../images/solo/categories/category_blessing.webp"
              alt="Guides"
            />
          }
        />
        <CategoryCard
          title="Hunter Tier List"
          link="/solo-leveling/tier-list"
          image={
            <StaticImage
              src="../../images/solo/categories/category_tier.jpg"
              alt="Tier List"
            />
          }
        />
        <CategoryCard
          title="Weapon Tier List"
          link="/solo-leveling/tier-list-weapons"
          image={
            <StaticImage
              src="../../images/solo/categories/category_tier2.jpg"
              alt="Tier List"
            />
          }
        />
        <CategoryCard
          title="Battlefield of Chaos"
          link="/solo-leveling/guides/battlefield-of-chaos"
          image={
            <StaticImage
              src="../../images/solo/categories/category_boc.jpg"
              alt="Battlefield of Chaos"
            />
          }
        />
        <CategoryCard
          title="Workshop of Brilliant Light"
          link="/solo-leveling/guides/workshop-of-brilliant-light"
          image={
            <StaticImage
              src="../../images/solo/categories/category_wobl.webp"
              alt="Guides"
            />
          }
        />
        <CategoryCard
          title="WOBL: Upper Floors"
          link="/solo-leveling/guides/wobl-upper-floors"
          image={
            <StaticImage
              src="../../images/solo/categories/category_wobl_upper.webp"
              alt="Guides"
            />
          }
        />
        <CategoryCard
          title="Simulation Gate"
          link="/solo-leveling/guides/simulation-gate"
          image={
            <StaticImage
              src="../../images/solo/categories/category_sim.webp"
              alt="Guides"
            />
          }
          isNew
        />
        <CategoryCard
          title="Core Review"
          link="/solo-leveling/guides/core-review"
          image={
            <StaticImage
              src="../../images/solo/categories/category_core.webp"
              alt="Core"
            />
          }
        />
        <CategoryCard
          title="Artifact Dungeons"
          link="/solo-leveling/guides/artifact-dungeons"
          image={
            <StaticImage
              src="../../images/solo/categories/category_ad.webp"
              alt="Guides"
            />
          }
        />
        <CategoryCard
          title="Guilds and Guild Boss"
          link="/solo-leveling/guides/guilds-and-guild-boss"
          image={
            <StaticImage
              src="../../images/solo/categories/category_guild.webp"
              alt="Guides"
            />
          }
        />
        <CategoryCard
          title="Characters list"
          link="/solo-leveling/characters"
          image={
            <StaticImage
              src="../../images/solo/categories/category_characters.jpg"
              alt="Characters"
            />
          }
        />
        <CategoryCard
          title="Artifact Sets"
          link="/solo-leveling/guides/artifact-sets"
          image={
            <StaticImage
              src="../../images/solo/categories/category_arti.png"
              alt="Artifact Sets"
            />
          }
        />
        <CategoryCard
          title="Artifact Stats"
          link="/solo-leveling/guides/artifact-stats"
          image={
            <StaticImage
              src="../../images/solo/categories/category_arti2.png"
              alt="Artifact Stats"
            />
          }
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default SoloIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Solo Leveling: Arise Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a wiki for Solo Leveling: Arise. Check our guides, tier lists and reviews for characters available in the game."
    game="solo"
  />
);
